<template>
  <div class="page-layout__content">
    <div class="container bootstrap snippets bootdeys">
      <div class="row">
        <div v-if="app.id" class="col-sm-12 col-md-12">
          <div class="panel panel-default invoice A4" id="invoice">
            <div class="panel-body sheet padding-10mm">
              <div v-if="app.is_client_paid" class="invoice-ribbon">
                <div class="ribbon-inner">PAID</div>
              </div>
              <div class="row">

                <div class="col-sm-4 top-left">
                  <img :src="$config.originalLogoUrl" alt="Cargo JET" class="invoice-logo logo">
                </div>

                <div class="col-sm-4 text-center">
                  <h3 class="app-category" > {{ getTranslation(app.service_category.slug) }} </h3>
                  <h4 class="app-service" v-if="app.by_land" >{{ getTranslation(app.service_option.title.land) }}</h4>
                  <h4 class="app-service" v-else >{{ getTranslation(app.service_option.title.air) }}</h4>
                </div>

                <div class="col-sm-4 top-right" :class="{'pr-100':app.is_client_paid}">
                  <h3 class="marginright">{{ getTranslation('INVOICE') }} - <span class="underline">{{ app.code }}</span></h3>
                  <span v-if="content.date" class="marginright invoice-date">{{ getTranslation('invoiceDateFrom') }} {{ content.date.day }} {{ content.date.month }} {{ content.date.year }} {{ getTranslation('shortYear')
                    }}</span>
                </div>

              </div>

              <hr>

              <div class="row">

                <div v-if="content.organization" class="col-6 from">
                  <p class="section__descr">{{ getTranslation('executor') }}</p>
                  <p class="lead marginbottom"><span class="font-weight-bold">{{ getTranslation('organization') }}: </span>
                    <router-link :to="content.organization.route">{{ content.organization.title }}</router-link>
                  </p>
                  <p v-if="content.organization.currencyAccount"><span class="font-weight-bold">{{ getTranslation('paymentAccount') }}:</span> {{ content.organization.currencyAccount || '-' }}</p>
                  <p v-if="content.organization.inn "><span class="font-weight-bold">{{ getTranslation('inn') }}:</span> {{ content.organization.inn || '-' }}</p>
                  <p v-if="content.organization.postalCode"><span class="font-weight-bold">{{ getTranslation('postalCode') }}:</span> {{ content.organization.postalCode || '-' }}</p>
                  <p v-if="content.organization.bankName"><span class="font-weight-bold">{{ getTranslation('bankName') }}:</span> {{ content.organization.bankName }}</p>
                  <p v-if="content.organization.bik"><span class="font-weight-bold">{{ getTranslation('bankBik') }}:</span> {{ content.organization.bik || '-' }}</p>
                </div>

                <div v-if="content.customer" class="col-6 to">
                  <p class="section__descr">{{ getTranslation('customer') }}</p>
                  <p class="lead marginbottom"><span class="font-weight-bold">{{ getTranslation('buyer') }}: </span>
                    <router-link :to="content.customer.route">{{ content.customer.title }}</router-link>
                  </p>
                  <p v-if="content.customer.currencyAccount"><span class="font-weight-bold">{{ getTranslation('paymentAccount') }}:</span> {{ content.customer.currencyAccount || '-' }}</p>
                  <p v-if="content.customer.inn"><span class="font-weight-bold">{{ getTranslation('inn') }}:</span> {{ content.customer.inn || '-' }}</p>
                  <p v-if="content.customer.postalCode"><span class="font-weight-bold">{{ getTranslation('postalCode') }}:</span> {{ content.customer.postalCode || '-' }}</p>
                  <p v-if="content.customer.bankName"><span class="font-weight-bold">{{ getTranslation('bankName') }}:</span> {{ content.customer.bankName }}</p>
                  <p v-if="content.customer.bik "><span class="font-weight-bold">{{ getTranslation('bankBik') }}:</span> {{ content.customer.bik || '-' }}</p>

                </div>

                <!--                <div class="col-4 text-right payment-details">-->
                <!--                  <p class="lead marginbottom payment-info">Payment details</p>-->
                <!--                  <p>Date: 14 April 2014</p>-->
                <!--                  <p>VAT: DK888-777 </p>-->
                <!--                  <p>Total Amount: $1019</p>-->
                <!--                  <p>Account Name: Flatter</p>-->
                <!--                </div>-->

              </div>

              <div v-if="content.totals" class="row mt-5">
                <div class="col-sm-3">
                  <div class="c-callout c-callout-danger">
                    <small class="text-muted">{{ getTranslation('priceWeight') }} </small><br>
                    <strong class="h4">{{ content.priceWeight }} </strong>
                  </div>
                </div><!--/.col-->
                <div class="col-sm-3">
                  <div class="c-callout c-callout-info">
                    <small class="text-muted">{{ getTranslation('quantity') }} </small><br>
                    <strong class="h4">{{ content.totals.totalQuantity }} </strong>
                  </div>
                </div><!--/.col-->
                <div class="col-sm-3">
                  <div class="c-callout c-callout-info">
                    <small class="text-muted">{{ getTranslation('volume') }} </small><br>
                    <strong class="h4">{{ content.totals.totalVolume_m }} m<sup>3</sup> </strong>
                  </div>
                </div><!--/.col-->
                <div class="col-sm-3">
                  <div class="c-callout c-callout-info">
                    <small class="text-muted">{{ getTranslation('weight') }} </small><br>
                    <strong class="h4">{{ content.totals.totalWeight }} {{ content.totals.w_unit }} </strong>
                  </div>
                </div><!--/.col-->

              </div><!--/.row-->

              <div class="row table-row">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th class="text-center" style="width:5%">#</th>
                    <th style="width:50%">{{ getTranslation('naming') }}</th>
                    <th class="text-right" style="width:15%">{{ getTranslation('quantityCount') }}</th>
                    <th class="text-right" style="width:15%">{{ getTranslation('price') }}</th>
                    <th class="text-right" style="width:15%">{{ getTranslation('totalSum') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="last-row">
                    <td class="text-center">1</td>
                    <td>{{ app.title }}</td>
                    <td class="text-right">1 {{ getTranslation('invoiceQtUnit') }}</td>
                    <td class="text-right">{{ content.price }}</td>
                    <td class="text-right">{{ content.price }}</td>
                  </tr>
                  </tbody>
                </table>

              </div>

              <div class="row">
                <div class="col-6 text-left pull-left invoice-total">

                </div>
                <div class="col-6 text-right pull-right invoice-total">
                  <p class="font-weight-bold">{{ getTranslation('invoiceSubtotal') }} : {{ content.price }}</p>
                  <!--                  <p>Discount (10%) : $101 </p>-->
                  <!--                  <p>VAT (8%) : $73 </p>-->
                  <p class="font-weight-bold">{{ getTranslation('invoiceTotal') }} : {{ content.price }} </p>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h5> {{ getTranslation('totalNaming', 1) }}, {{ getTranslation('invoiceForSum', content.price) }} {{ content.currencyCode }}</h5>
                  <h5 v-if="content.priceString" class="font-weight-bolder"> {{ capitalizeFLetter(content.priceString) }}</h5>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Invoice",
  data() {
    return {
      content: {},
      app: { id: null }
    };
  },
  created() {


    this.fetchApp().then(() => {
      this.fetchInvoiceData();

      this.setHeadings({
        title: this.getTranslation('invoice'),
        toBack: {
          label: this.getTranslation('backTo'),
          route: { name: 'application.get', params: { id: this.$route.params.id } }
        },
        actions: [
          {
            label: this.getTranslation('Print Invoice'),
            action: this.print,
            c_icon: 'cil-print',
            buttonClass: 'btn-success'
          }
        ],
        breadcrumbs: [
          {
            to: { name: 'application.getList' },
            text: this.getTranslation('applications')
          },
          {
            to: { name: 'application.get', params: { id: this.$route.params.id } },
            text: this.app.title
          },
          {
            to: { name: 'application.get', params: { id: this.$route.params.id } },
            text: this.getTranslation('invoice')
          }
        ],
      });
    });
  },
  methods: {
     print () {
      // Pass the element id here
       this.$htmlToPaper('invoice');
    },
    fetchInvoiceData() {
      this.axios.get(this.$urls.application.invoice.url.replace(':id', this.$route.params.id))
          .then(response => {
            this.content = response.data.data;
          }).catch(() => {
      });
    },
    fetchApp() {
      return this.axios.get(this.$urls.application.application.url.replace(':id', this.$route.params.id))
          .then(res => {
            this.app = res.data.data;
          }).catch(() => {
          });
    }
  }
}
</script>

<style scoped>


/*Invoice*/
.invoice .top-left .invoice-logo {
  width: 40%;
  height: auto;
}


.invoice .top-right {
  text-align: right;
}

.invoice-date {
  font-size: 20px;
}

.pr-100 {
  padding-right: 100px !important;
}

.invoice .table-row {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 25px;
}

.invoice .payment-info {
  font-weight: 500;
}

.invoice .table-row .table > thead {
  border-top: 1px solid #ddd;
}

.invoice .table-row .table > thead > tr > th {
  border-bottom: none;
}

.invoice .table > tbody > tr > td {
  padding: 8px 20px;
}

.invoice .invoice-total {
  margin-right: -10px;
  font-size: 16px;
}

.invoice .last-row {
  border-bottom: 1px solid #ddd;
}

.invoice-ribbon {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -1px;
  right: 14px;
}

.ribbon-inner {
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  left: -5px;
  top: 11px;
  width: 120px;
  background-color: #66c591;
  font-size: 15px;
  color: #fff;
}

.ribbon-inner:before, .ribbon-inner:after {
  content: "";
  position: absolute;
}

.ribbon-inner:before {
  left: 0;
}

.ribbon-inner:after {
  right: 0;
}

@media (max-width: 575px) {
  .invoice .top-left, .invoice .top-right, .invoice .payment-details {
    text-align: center;
  }

  .invoice .from, .invoice .to, .invoice .payment-details {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
  }

  .invoice p.lead, .invoice .from p.lead, .invoice .to p.lead, .invoice .payment-details p.lead {
    font-size: 22px;
  }

  .invoice .btn {
    margin-top: 10px;
  }
}

</style>